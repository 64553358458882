import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { Fragment, useEffect, useRef, useState } from "react";
import useSWR from "swr";
import { getLatestNews, getter } from "../api";
import {
  LatestNewsParams,
  News,
  Option,
  OrganizationStub,
  PaginatedList,
  Success,
} from "../api/types";
import Avatar from "./Avatar";
import { NoResult } from "./NoResult";
import SpinnerCustom from "./SpinnerCustom";
import { dateFormatISO, isValidJson } from "./Utils/commons";

const Tooltip = ({
  value,
  className,
}: {
  value: string;
  className?: string;
}) => {
  return (
    <div
      id="tooltip-bottom"
      role="tooltip"
      className={`z-top absolute ${className} tooltip invisible top-full inline-block w-max max-w-xs rounded-lg bg-gray-900 px-2 py-1 text-xs font-medium break-all text-white shadow-xs group-hover:visible`}
    >
      {value}
    </div>
  );
};

const PAGINATION_PAGE_SIZE = 50;

const LatestNews = () => {
  const newsRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [data, setData] = useState<Success<PaginatedList<News>>>();
  const [latestNews, setLatestNews] = useState<News[]>([]);
  const [entitySelected, setEntitySelected] = useState<Option>({
    label: "All",
    value: "all",
  });

  const [entityOptions, setEntityOptions] = useState<Option[]>([]);

  const { data: vertexEntities } = useSWR<Success<OrganizationStub[]>>(
    `/api/people_map/organizations/vertex_entities`,
    getter,
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    if (vertexEntities && vertexEntities.data.length > 0) {
      setEntityOptions([
        ...[
          {
            label: "All",
            value: "all",
          },
        ],
        ...vertexEntities.data.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      ]);
    }
  }, [vertexEntities]);

  useEffect(() => {
    if (newsRef && newsRef.current) {
      const handleScroll = () => {
        if (
          newsRef.current &&
          newsRef.current.offsetHeight + newsRef.current.scrollTop >=
            newsRef.current.scrollHeight
        ) {
          setPageIndex(pageIndex + 1);
        }
      };
      newsRef.current.addEventListener("scroll", handleScroll);
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        newsRef.current?.removeEventListener("scroll", handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsRef, data]);

  useEffect(() => {
    if (
      data &&
      data?.data?.items?.length > 0 &&
      data?.data?.page === pageIndex
    ) {
      if (pageIndex > 1) {
        if (latestNews.length < data?.data?.total) {
          setLatestNews([...latestNews, ...data.data.items]);
        }
      } else {
        setLatestNews(data.data.items);
      }
    } else {
      setLatestNews([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (totalPages === 0 || totalPages >= pageIndex) {
      setLoading(true);
      let filterParams: LatestNewsParams = {
        page: pageIndex,
        limit: PAGINATION_PAGE_SIZE,
        dashboard: true,
      };

      if (entitySelected.value !== "all") {
        filterParams.vertex_entity = entitySelected.value;
      }

      getLatestNews(filterParams)
        .then((response) => {
          if (response) {
            setData(response);
            setPageIndex(response.data.page);
            setTotalPages(response.data.pages);
          }
        })
        .catch((error) => {
          setData(undefined);
          setPageIndex(1);
          setTotalPages(0);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, entitySelected]);

  useEffect(() => {
    const dashboardLatestNews =
      localStorage.getItem("dashboardLatestNews") ?? "";
    if (dashboardLatestNews.length > 0 && isValidJson(dashboardLatestNews)) {
      setEntitySelected(JSON.parse(dashboardLatestNews));
    }
  }, []);

  return (
    <div className="h-full overflow-hidden border border-1 bg-white pt-0.5 pr-0! pl-4 whitespace-normal shadow-xs sm:w-1/2 sm:rounded-lg sm:pb-4 lg:h-[60%] lg:w-full">
      <div className="my-2.5 flex flex-row items-center justify-between">
        <div className="flex flex-row">
          <span className="mr-2 h-5 w-2 rounded-xs bg-blue-900"></span>
          <div className="mr-2 text-sm font-bold sm:text-xs md:text-sm">
            News
          </div>
        </div>
        <div className="relative mr-3">
          <Popover>
            {({ open, close }) => (
              <>
                <Popover.Button
                  className={`flex min-w-[100px] flex-row items-center justify-between gap-x-2 rounded-md bg-slate-100 px-3 py-2 ${open ? "" : "text-opacity-90"} focus:outline-hidden`}
                >
                  <div className="text-xs font-semibold text-gray-900">{`${entitySelected.label}`}</div>
                  <ChevronDownIcon className="h-4 w-4" />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="z-top absolute top-10 right-0 w-max">
                    {entityOptions.length > 0 && (
                      <div className="h-fit overflow-y-auto rounded-lg border border-gray-200 bg-white p-2 text-xs">
                        {entityOptions.map((item: Option) => (
                          <div
                            key={item.value}
                            className={`flex cursor-pointer items-center rounded px-4 py-2 ${
                              entitySelected.value === item.value
                                ? "bg-blue-900 text-white"
                                : "hover:bg-gray-100"
                            }`}
                            onClick={() => {
                              setEntitySelected(item);
                              setTotalPages(0);
                              localStorage.setItem(
                                "dashboardLatestNews",
                                JSON.stringify(item),
                              );
                              close();
                            }}
                          >
                            {item.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
      </div>
      <div
        className="sm:show-scrollbar flex h-[calc(100vh-9.97rem)]! flex-col overflow-x-hidden! overflow-y-auto pr-6 sm:h-[92%] sm:max-h-[88%]"
        ref={newsRef}
      >
        {!loading && latestNews.length === 0 && <NoResult message="No News" />}
        {latestNews.map((latest_new: News) => (
          <div
            key={latest_new.id}
            className="border-b-[1px] border-gray-300 py-2"
          >
            <div className="flex flex-row items-center gap-x-2">
              {latest_new.organizations.map((org) => (
                <Link
                  href={`/organizations/${org.id}`}
                  key={org.id}
                  className="group relative"
                >
                  <Avatar
                    name={org.name}
                    size={50}
                    url={org.image_url}
                    shape="square"
                    orgId={org.id}
                  />
                  <Tooltip value={org.name} className="left-0" />
                </Link>
              ))}
              <Link
                href={latest_new.url}
                className="w-full"
                target="_blank"
                rel="noreferrer"
              >
                <div className="relative flex flex-col">
                  <span className="line-clamp-2 text-left text-xs font-semibold break-normal text-black">
                    {latest_new.title}
                  </span>
                  <div className="text-2xs flex flex-row justify-between font-light text-black">
                    <div>{latest_new.source}</div>
                    <div>{dateFormatISO(latest_new.publish_date)}</div>
                  </div>
                  <Tooltip
                    value={latest_new.url}
                    className="right-1/2 left-1/2 -translate-x-1/2"
                  />
                </div>
              </Link>
            </div>
          </div>
        ))}
        {loading && <SpinnerCustom />}
      </div>
    </div>
  );
};

export default LatestNews;
