import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { CollaborationThread } from "../../api/types";
import ForumView from "../Forum/ForumView";
import { ThreadForm } from "../Forum/ThreadForm";

export const NetWorkCollaboration = () => {
  const [newThread, setNewThread] = useState(false);
  const [newThreadId, setNewThreadId] = useState<number>();

  const updateThread = (thread: CollaborationThread) => {
    setNewThread(false); //Close form
    setNewThreadId(thread.id); // update new thread on list
  };

  return (
    <div className="sm:w-1/2 lg:h-[40%] lg:w-full lg:pt-3">
      <div className="h-full min-w-[270px] overflow-hidden border border-1 bg-white px-4 pr-0! shadow-xs sm:rounded-lg sm:p-4">
        <div className="flex flex-row items-center pt-2 pr-4 md:pt-0">
          <span className="mr-2 h-5 w-2 rounded-xs bg-blue-900"></span>
          <div className="mr-2 text-sm font-bold sm:text-xs md:text-sm">
            Network Collaboration
          </div>
          <PlusCircleIcon
            className="mr-1 h-5 w-5 cursor-pointer stroke-blue-900"
            onClick={() => setNewThread(true)}
          />
        </div>
        {newThread && (
          <div className="h-[92%] overflow-auto pr-6">
            <ThreadForm
              onClose={() => setNewThread(false)}
              onSuccess={updateThread}
            />
          </div>
        )}
        <div className="sm:show-scrollbar h-[calc(100vh-8.79rem)]! overflow-y-auto pr-6 sm:h-[88%]!">
          <ForumView newThreadId={newThreadId} />
        </div>
      </div>
    </div>
  );
};
