import { Tab } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { debounce } from "lodash";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Companies } from "./Dashboard/Companies";
import { DashboardHeader, dashboardTabs } from "./Dashboard/DashboardUltils";
import { DashboardUpdates } from "./Dashboard/DashboardUpdates";
import { NetWorkCollaboration } from "./Dashboard/NetWorkCollaboration";
import LatestNews from "./LatestNews";

const Dashboard: React.FC = () => {
  const isDesktop = useMediaQuery({
    minWidth: 640,
  });
  const container = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const { tabId } = router.query;
  const [currentTab, setCurrentTab] = useState(dashboardTabs[0]);
  const [showHiddenTabs, setShowHiddenTabs] = useState<boolean>(false);
  const [visibleTabs, setVisibleTabs] =
    useState<DashboardHeader[]>(dashboardTabs);
  const [hiddenTabs, setHiddenTabs] = useState<DashboardHeader[]>([]);

  const showTabs = useCallback(() => {
    const curContainer = container.current;
    if (!curContainer) return;
    requestAnimationFrame(() => {
      const containerWidth = curContainer.getBoundingClientRect().width;
      const children = Array.from(curContainer.children) as HTMLElement[];

      let width = 0;
      const newVisibleTabs: DashboardHeader[] = [];
      const newHiddenTabs: DashboardHeader[] = [];

      dashboardTabs.forEach((tab, index) => {
        const tabElement = children[index] as HTMLElement | undefined;
        if (!tabElement) return;
        const tabWidth = tabElement.getBoundingClientRect().width;
        if (tabWidth + width <= containerWidth - 32) {
          newVisibleTabs.push(tab);
          width += tabWidth;
        } else {
          newHiddenTabs.push(tab);
        }
      });

      setVisibleTabs(newVisibleTabs);
      setHiddenTabs(newHiddenTabs);
    });
  }, []);

  useEffect(() => {
    showTabs();
  }, [showTabs]);

  useEffect(() => {
    const handleResize = debounce(() => {
      window.requestAnimationFrame(() => {
        showTabs();
      });
    }, 300);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showTabs]);

  useEffect(() => {
    let selectedTab = dashboardTabs[0];
    if (tabId) {
      selectedTab =
        dashboardTabs.find((tab) => tab.id === tabId) || selectedTab;
    }
    setCurrentTab(selectedTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabId, dashboardTabs]);

  return (
    <div>
      <div className="flex-col lg:overflow-hidden">
        {isDesktop ? (
          <div className="flex h-[calc(100vh-7rem)] flex-col gap-3 lg:flex-row">
            <div className="flex flex-col gap-y-3 lg:w-2/3">
              <div className="flex h-full w-full flex-col gap-y-3 sm:flex-row sm:gap-x-3">
                <Companies />
                <DashboardUpdates />
              </div>
            </div>
            <div className="flex min-w-[270px] flex-col gap-y-3 sm:flex-row sm:gap-x-3 sm:gap-y-0 lg:w-1/3 lg:flex-col lg:gap-x-0">
              <LatestNews />
              <NetWorkCollaboration />
            </div>
          </div>
        ) : (
          <Tab.Group>
            <Tab.List className={"relative z-10"}>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {visibleTabs.map((tab) => (
                    <Tab key={tab.id} className={"focus:outline-hidden"}>
                      <div
                        className={`flex h-10 w-max items-center justify-center border-b-4 px-4 pt-1 text-lg leading-7 font-normal text-gray-900 ${
                          currentTab.id === tab.id
                            ? "border-b-blue-900"
                            : "border-transparent"
                        }`}
                        onClick={() => {
                          setCurrentTab(tab);
                          router.push({
                            search: `?tabId=${tab.id}`,
                          });
                          setShowHiddenTabs(false);
                        }}
                      >
                        {tab.headerName}
                      </div>
                    </Tab>
                  ))}
                </div>
                {hiddenTabs.length > 0 && (
                  <div
                    className="mr-3 flex h-8 w-8 items-center justify-center rounded-lg bg-gray-300 outline-hidden"
                    onClick={() => {
                      setShowHiddenTabs(!showHiddenTabs);
                    }}
                    onBlur={() => {
                      setShowHiddenTabs(false);
                    }}
                  >
                    <EllipsisVerticalIcon className="h-6 w-6 fill-gray-500" />

                    <div
                      className={`absolute top-full right-0 z-100 flex w-50 flex-col items-start rounded-xl border border-gray-200 bg-white p-2 drop-shadow-2xl sm:w-48 md:right-0 ${showHiddenTabs ? "visible" : "invisible"}`}
                    >
                      {hiddenTabs.map((tab) => (
                        <Tab key={tab.id} className="w-full">
                          <button
                            className={`flex w-max items-start justify-center rounded-lg px-1 text-start leading-7 font-normal ${
                              currentTab.id === tab.id
                                ? "bg-blue-50 font-semibold text-blue-900"
                                : "text-gray-900"
                            }`}
                            onClick={() => {
                              setCurrentTab(tab);
                              router.push({
                                search: `?tabId=${tab.id}`,
                              });
                              close();
                            }}
                          >
                            {tab.headerName}
                          </button>
                        </Tab>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div ref={container} className="invisible flex h-0 w-full">
                {dashboardTabs.map((tab) => (
                  <Tab
                    key={`${tab.id}-hidden`}
                    className={"focus:outline-hidden"}
                  >
                    <div className="flex h-10 w-max items-center justify-center px-4 text-lg leading-7 font-normal text-gray-900">
                      {tab.headerName}
                    </div>
                  </Tab>
                ))}
              </div>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <Companies />
              </Tab.Panel>
              <Tab.Panel>
                <DashboardUpdates />
              </Tab.Panel>
              <Tab.Panel>
                <LatestNews />
              </Tab.Panel>
              <Tab.Panel>
                <NetWorkCollaboration />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
