import { Tab } from "@headlessui/react";
import Link from "next/link";
import { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { getter } from "../../api";
import {
  DashboardOrganization,
  OrganizationInvestmentDetail,
  Success,
} from "../../api/types";
import Avatar from "../Avatar";
import SpinnerCustom from "../SpinnerCustom";
import { dateFormatISO } from "../Utils/commons";
import { classNames as classes } from "../utils";
import { options } from "./DashboardUltils";

export const Companies = () => {
  const [index, setIndex] = useState(0);

  const { data: vpcs, isValidating: loadingVpcs } = useSWR<
    Success<OrganizationInvestmentDetail[]>
  >("/api/people_map/vpcs/recent", getter, options);

  const { data: organizations, isValidating: loadingOrgs } = useSWR<
    Success<DashboardOrganization[]>
  >("/api/people_map/organizations/recent", getter, options);

  useEffect(() => {
    const prevTab = localStorage.getItem("dashboardCompaniesTab") ?? "0";
    setIndex(parseInt(prevTab));
  }, []);

  const tabHeaders = useMemo(() => {
    return [
      {
        index: 0,
        name: "Recently Invested",
      },
      {
        index: 1,
        name: "New",
      },
    ];
  }, []);

  return (
    <div className="flex h-full w-full min-w-[270px] flex-row items-start border border-1 bg-white pt-0.5 pr-0! pl-4 shadow-xs sm:rounded-lg sm:pb-4">
      <Tab.Group
        selectedIndex={index}
        onChange={(index) => {
          setIndex(index);
          localStorage.setItem("dashboardCompaniesTab", index.toString());
        }}
        defaultIndex={index}
      >
        <div className="flex h-full w-full flex-col">
          <div className="my-3 flex flex-row items-center justify-between pr-4">
            <Tab.List className="flex w-full items-center justify-between">
              <div className="flex">
                <span className="mr-2 h-5 w-2 rounded-xs bg-blue-900"></span>
                <div className="mr-2 text-sm font-bold sm:text-xs md:text-sm">
                  Companies
                </div>
              </div>
              <div className="flex items-center gap-x-2 border-b-[1px] border-gray-300">
                {tabHeaders.map((tabHeader) => (
                  <div key={tabHeader.index} className="group">
                    <Tab
                      className={({ selected }) =>
                        classes(
                          "sm:text-2xs px-1 text-xs font-medium whitespace-nowrap focus:ring-0 focus:outline-hidden md:text-xs",
                          selected || tabHeader.index === index
                            ? "text-blue-900"
                            : "text-gray-500 group-hover:text-gray-700",
                        )
                      }
                    >
                      {tabHeader.name}
                    </Tab>
                    <div
                      className={`h-1 w-full rounded-t-md ${
                        tabHeader.index === index
                          ? "bg-blue-900"
                          : "group-hover:bg-gray-300"
                      }`}
                    ></div>
                  </div>
                ))}
              </div>
            </Tab.List>
          </div>
          <Tab.Panels
            className={`sm:show-scrollbar h-[calc(100vh-9.66rem)]! overflow-y-auto pr-6 sm:h-full`}
          >
            <Tab.Panel>
              <div className="flex flex-col text-xs">
                {!loadingVpcs ? (
                  vpcs &&
                  vpcs.data.map((vpc: OrganizationInvestmentDetail) => (
                    <Link
                      className="flex flex-col gap-y-2 border-b-[1px] border-gray-300 py-2 text-black"
                      key={vpc.id}
                      href={`/organizations/${vpc.investee_organization.id}`}
                    >
                      <div className="flex gap-x-2">
                        <Avatar
                          name={vpc.investee_organization.name}
                          size={50}
                          url={vpc.investee_organization.image_url}
                          shape="square"
                        />
                        <div className="flex flex-col">
                          <div className="font-bold">
                            {vpc.investee_organization.name}
                          </div>
                          <div className="line-clamp-2 break-normal">
                            {vpc.investee_organization.description}
                          </div>
                        </div>
                      </div>
                      {vpc.investment_date && (
                        <div className="flex items-center justify-between text-gray-500">
                          <div>{dateFormatISO(vpc.investment_date)}</div>
                          <div>{vpc.investor_fund.name}</div>
                        </div>
                      )}
                    </Link>
                  ))
                ) : (
                  <SpinnerCustom />
                )}
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="flex flex-col text-xs">
                {!loadingOrgs ? (
                  organizations &&
                  organizations.data.map((org: DashboardOrganization) => (
                    <Link
                      className="flex flex-col gap-y-2 border-b-[1px] border-gray-300 py-2 text-black"
                      key={org.id}
                      href={`/organizations/${org.id}`}
                    >
                      <div className="flex flex-row gap-x-2">
                        <Avatar
                          name={org.name}
                          size={50}
                          url={org.logo_url}
                          shape="square"
                          orgId={org.id}
                        />
                        <div className="flex flex-col">
                          <div className="font-bold">{org.name}</div>
                          <div
                            className="line-clamp-2"
                            style={{ wordBreak: "break-word" }}
                          >
                            {org.description}
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <SpinnerCustom />
                )}
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
};
